<template>
  <v-content>
    <v-overlay :value="verifying || waiting">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-container v-if="verifySuccess">
      <v-app-bar app>
        <v-toolbar-title class="headline text-uppercase">
          <span
            ><a href="/"><v-img max-height="60" max-width="240" src="../../assets/logos/AuditEngineLogo.png"></v-img></a
          ></span>
          <span class="font-weight-light"></span>
        </v-toolbar-title>
      </v-app-bar>

      <v-container>
        <v-toolbar color="primary" dark>
          <v-spacer></v-spacer>
          <v-btn :disabled="!isValid" @click="$refs.files.click()" depressed>
            <input ref="files" @change="filesSelected" id="fileSelector" type="file" style="display: none" multiple />
            <span class="mr-2">Select Files</span>
          </v-btn>
        </v-toolbar>

        <v-card>
          <v-card-title> </v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="6" sm="6" md="6" class="g-layout">
                <v-text-field
                  v-model="first_name"
                  label="Name"
                  required
                  :rules="[rules.required]"
                  validate-on-blur
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="6" md="6" class="g-layout">
                <v-text-field v-model="email" label="Email" outlined required
                :rules="[rules.required, rules.email]"
                validate-on-blur></v-text-field>
              </v-col>
              <v-col cols="6" class="g-layout">
                <v-select
                  v-model="US_state"
                  :items="US_states"
                  @change="changeUSState"
                  item-text="name"
                  item-value="value"
                  label="State"
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="6" class="g-layout">
                <v-select v-model="county" :items="jurisdictions" label="County" outlined></v-select>
              </v-col>
              <v-col cols="6" class="g-layout">
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      label="Election Date"
                      hint="MM-DD-YYYY format"
                      persistent-hint
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      outlined
                      @blur="date = parseDate(date)"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="date" no-title @input="menu1 = false"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6" class="g-layout">
                <v-select
                  v-model="file_type"
                  :items="file_types"
                  item-text="description"
                  item-value="value"
                  label="File Type"
                  outlined
                ></v-select>
              </v-col>
            </v-row>
            <v-data-table
              :headers="headers"
              :items="files"
              class="elevation-1"
              :loading="uploadedFilesLoading"
              style="width: 100%"
            >
              <template v-slot:item.progress="{ item }">
                <v-progress-linear :value="item.progress" height="25">
                  <strong>{{ Math.ceil(item.progress) }}%</strong>
                </v-progress-linear>
              </template>

              <template v-slot:item.byterate="{ item }">
                {{ (8 * Math.round((10 * item.byterate) / 1024 / 1024)) / 10 }}Mbps
              </template>

              <template v-slot:item.status="{ item }">
                <v-chip v-if="item.status.success" class="ma-2" color="green" text-color="white">Success</v-chip>
                <v-chip v-else-if="item.status.failed" class="ma-2" color="red" text-color="white">Failed</v-chip>
                <v-chip v-else-if="item.status.canceled" class="ma-2" text-color="white">Canceled</v-chip>
                <v-chip v-else-if="item.status.uploading" class="ma-2" color="primary" text-color="white"
                  >Uploading</v-chip
                >
                <v-chip v-else class="ma-2" color="primary" text-color="white">Ready</v-chip>
              </template>

              <template v-slot:item.actions="{ item }">
                <v-btn icon v-if="item.status.uploading" @click.prevent="cancelFileUpload(item)">
                  <v-icon>mdi-stop</v-icon>
                </v-btn>
                <v-btn icon v-else-if="!item.status.success" @click.prevent="uploadFile(item)">
                  <v-icon>mdi-upload</v-icon>
                </v-btn>

                <v-btn icon v-if="!item.status.uploading" @click.prevent="removeFile(item)">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="uploadFiles" :disabled="files.length == 0">Upload All</v-btn>
            <v-btn @click="clearFiles" :disabled="files.length == 0">Remove All</v-btn>
            <v-btn @click="clearFiles" :disabled="files.length == 0">Exit</v-btn>
          </v-card-actions>
        </v-card>
      </v-container>
    </v-container>

    <v-container v-else-if="!verifying">
      <v-row justify="center" align="center">
        <v-col cols="12">
          <v-col cols="12" class="d-flex justify-center">
            <a href="/"
              ><v-img max-height="350" max-width="550" src="../../assets/logos/AuditEngineLogo.png"></v-img
            ></a>
          </v-col>

          <v-col cols="12" class="d-flex justify-center">
            <h3>Opps! We are sorry.</h3>
          </v-col>
        </v-col>
      </v-row>
    </v-container>
  </v-content>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { AbbrCountries } from './../../services/AbbrCountries.js'
import { AbbrStates } from './../../services/AbbrStates.js'
// import { USjurisdictions } from '../Elections/jurisdictions.js'

const PART_SIZE = 4 * 1024 * 1024 * 1024
//const PART_SIZE = 1 * 1024 * 1024;

function updateProgress(fileObj) {
  let total = 0
  let loaded = 0
  let byterate = 0.0
  let complete = 1

  for (let i = 0; i < fileObj.uploadOptions.total.length; ++i) {
    loaded += +fileObj.uploadOptions.loaded[i] || 0
    total += fileObj.uploadOptions.total[i] || 0
    if (fileObj.uploadOptions.loaded[i] != fileObj.uploadOptions.total[i]) {
      // Only count byterate for active transfers
      byterate += +fileObj.uploadOptions.byterate[i] || 0
      complete = 0
    }
  }

  fileObj.byterate = byterate
  fileObj.progress = Math.floor((100 * loaded) / fileObj.file.size)
}

function sendToS3(fileObj, index) {
  let size = fileObj.uploadOptions.blobs[index].size
  let request = (fileObj.uploadOptions.uploadXHR[index] = new XMLHttpRequest())

  request.onreadystatechange = function () {
    if (request.readyState === 4) {
      // 4 is DONE
      // self.uploadXHR[index] = null;
      if (request.status !== 200) {
        for (var i = 0; i < fileObj.uploadOptions.uploadXHR.length; ++i) {
          fileObj.uploadOptions.uploadXHR[i].abort()
        }

        if (!fileObj.status.canceled && !fileObj.status.failed) {
          fileObj.status.failed = true
          fileObj.callback.onFailed(fileObj)
        }

        fileObj.status.uploading = false
        return
      } else {
        const searchString = new URL(fileObj.options.urls[index]).search.substring(1)
        const partNumber = decodeURIComponent(
          searchString.replace(
            new RegExp(
              '^(?:.*[&\\?]' + encodeURIComponent('partNumber').replace(/[\.\+\*]/g, '\\$&') + '(?:\\=([^&]*))?)?.*$',
              'i'
            ),
            '$1'
          )
        )
        const uploadPart = {
          ETag: request.getResponseHeader('ETag').replace(/"/g, ''),
          PartNumber: parseInt(partNumber),
        }

        fileObj.options.uploadParts.push(uploadPart)

        if (fileObj.uploadOptions.blobs.length == fileObj.options.uploadParts.length) {
          // console.log("Upload success")
          fileObj.status.uploading = false
          fileObj.status.success = true
          let date = new Date()
          fileObj.completed_at = date.toISOString().substring(0, 19).replace('T', ' ')
          fileObj.callback.onSuccess(fileObj)
          return
        }
      }

      updateProgress(fileObj)
    }
  }

  request.upload.onprogress = function (e) {
    if (e.lengthComputable) {
      fileObj.uploadOptions.total[index] = size
      fileObj.uploadOptions.loaded[index] = e.loaded
      if (fileObj.uploadOptions.lastUploadedTime[index]) {
        let time_diff = (new Date().getTime() - fileObj.uploadOptions.lastUploadedTime[index]) / 1000
        if (time_diff > 0.005) {
          // 5 miliseconds has passed
          let byterate =
            (fileObj.uploadOptions.loaded[index] - fileObj.uploadOptions.lastUploadedSize[index]) / time_diff
          fileObj.uploadOptions.byterate[index] = byterate
          fileObj.uploadOptions.lastUploadedTime[index] = new Date().getTime()
          fileObj.uploadOptions.lastUploadedSize[index] = fileObj.uploadOptions.loaded[index]
        }
      } else {
        fileObj.uploadOptions.byterate[index] = 0
        fileObj.uploadOptions.lastUploadedTime[index] = new Date().getTime()
        fileObj.uploadOptions.lastUploadedSize[index] = fileObj.uploadOptions.loaded[index]
      }

      // Only send update to user once, regardless of how many
      // parallel XHRs we have (unless the first one is over).
      if (index == 0 || fileObj.uploadOptions.total[0] == fileObj.uploadOptions.loaded[0]) updateProgress(fileObj)
    }
  }

  request.open('PUT', fileObj.options.urls[index], true)
  request.send(fileObj.uploadOptions.blobs[index])
}

function UploadToS3(fileObj) {
  fileObj.uploadOptions = {
    uploadXHR: [],
    byterate: [],
    lastUploadedSize: [],
    lastUploadedTime: [],
    loaded: [],
    total: [],
    blobs: [],
    jurisdictions: [],
  }

  let partNum = 0,
    start = 0,
    end
  while (start < fileObj.file.size) {
    end = Math.min(start + PART_SIZE, fileObj.file.size)
    let filePart = fileObj.file.slice(start, end)
    // this is to prevent push blob with 0Kb
    if (filePart.size > 0) fileObj.uploadOptions.blobs.push(filePart)
    start = PART_SIZE * ++partNum
  }

  fileObj.status.uploading = true
  fileObj.status.success = false
  fileObj.status.failed = false
  fileObj.status.canceled = false
  let date = new Date()
  fileObj.started_at = date.toISOString().substring(0, 19).replace('T', ' ')
  for (let i = 0; i < fileObj.uploadOptions.blobs.length; i++) {
    sendToS3(fileObj, i)
  }
}

const validators = {
  alphabet: /^[A-Za-z\s]+$/,
  email: /\S+@\S+\.\S+/,
  number: /^[\d]+$/,
}

export default {
  name: 'GenralUploadView',
  components: {},
  computed: {
    ...mapGetters(['uploadRequestId', 'uploadEmail','jurisdictionsList']),
    isValid: function () {
      if (this.id > 0) {
        return !(this.first_name.length === 0 || this.email === 0)
      } else {
        return !(this.first_name.length === 0 || this.email.length === 0)
      }
    },
  },
  async mounted() {
    await this.$store.dispatch('getJurisdiction')   
    this.jurisdictions = this.jurisdictionsList['CA']
    this.county = this.jurisdictions[0]
    // this.verifying = true
    // const payload = {
    //   request_id: this.id,
    // }
    // let response = await this.$store.dispatch("verifyUploadToken", payload)
    // if(response.error){
    //     this.verifySuccess = false
    //     this.text = response.error
    // }
    // else {
    //     this.verifySuccess = true
    //     this.text = "Election: " + response.election + ", Requested By: " + response.requested_by
    // }
    // this.verifying = false
  },
  data() {
    return {
      dialog: false,
      verifying: false,
      waiting: false,
      verifySuccess: true,
      text: '',
      first_name: '',
      email: '',
      uploadedFilesLoading: false,
      request_id: 0,
      date: new Date().toISOString().substr(0, 10),
      files: [],
      countries: AbbrCountries,
      US_states: AbbrStates,
      jurisdictions: [],
      county: '',
      state: '',
      US_state: 'CA',
      menu1: false,
      file_types: [
        { value: 'BIA', description: 'Ballot Image Archive' },
        { value: 'CVR', description: 'Cast Vote Records' },
        { value: 'EIF', description: 'Election Information' },
        { value: 'BOF', description: 'Ballot Options' },
        { value: 'STC', description: 'Manual Styles to Contests' },
        { value: 'ANN', description: 'Annotations' },
        { value: 'ADJ', description: 'Adjudications' },
        { value: 'JOB', description: 'Job Configuration' },
        { value: 'SUM', description: 'Summary Results' },
        { value: 'SMF', description: 'Style Master PDF Files' },
        { value: 'OTH', description: 'Other' },
      ],
      file_type: 'BIA',
      headers: [
        { text: 'File Name', value: 'file.name', width: '15%' },
        { text: 'File Type', value: 'file_type', width: '10%' },
        { text: 'Size', value: 'size', width: '10%' },
        { text: 'Progress', value: 'progress', width: '15%' },
        { text: 'Speed', value: 'byterate', width: '10%' },
        { text: 'Started', value: 'started_at', width: '10%' },
        { text: 'Completed', value: 'completed_at', width: '10%' },
        { text: 'Status', value: 'status', width: '10%' },
        { text: 'Actions', value: 'actions', width: '10%' },
      ],
      rules: {
        required: (value) => !!value || 'Required.',
        onlyAlphabet: (value) => validators.alphabet.test(value) || 'Invalid value.',
        integer: (value) => validators.integer.test(value) || 'Invalid value.',
        float: (value) => validators.float.test(value) || 'Invalid value',
        email: (value) => /.+@.+\..+/.test(value) || 'E-mail must be valid',
      },
    }
  },
  methods: {
    changeUSState(state) {
      this.jurisdictions = this.jurisdictionsList[state]
      if (this.jurisdictions.length > 0) this.county = this.jurisdictions[0]
    },
    filesSelected() {
      const files = document.getElementById('fileSelector').files
      for (let i = 0; i < files.length; i++) {
        let item = {
          file: files[i],
          progress: 0,
          byterate: 0,
          file_type: this.file_type,
          uploadOptions: {},
          options: {},
          status: {
            uploading: false,
            failed: false,
            canceled: false,
            success: false,
          },
        }

        const bytes = files[i].size
        if (bytes < 1024) {
          item.size = bytes.toString() + 'B'
        } else if (bytes < 1024 * 1024) {
          item.size = Math.round((100 * bytes) / 1024) / 100 + 'KB'
        } else if (bytes < 1024 * 1024 * 1024) {
          item.size = Math.round((100 * bytes) / 1024 / 1024) / 100 + 'MB'
        } else {
          item.size = Math.round((100 * bytes) / 1024 / 1024 / 1024) / 100 + 'GB'
        }
        this.files.push(item)
      }
    },
    clearFiles() {
      for (let i = 0; i < this.files.length; i++) {
        if (this.files[i].uploading) {
          this.cancelFileUpload(this.files[i])
        }
      }

      if (this.files.length == 1) {
        document.getElementById('fileSelector').value = ''
      }

      this.files = []
    },
    uploadFiles() {
      for (let i = 0; i < this.files.length; i++) {
        if (!this.files[i].status.uploading) {
          this.uploadFile(this.files[i])
        }
      }
    },
    removeFile(item) {
      if (this.files.length == 1) {
        document.getElementById('fileSelector').value = ''
      }

      const index = this.files.indexOf(item)
      this.files.splice(index, 1)
    },

    async uploadFile(item) {
      let payload = {
        state: this.US_state,
        county: this.county,
        file_type: this.file_type,
        upload_date: this.date,
        file_name: item.file.name,
        name:this.first_name,
        email:this.email,
        content_type: item.file.type == '' ? 'application/octet-stream' : item.file.type,
        chunk_count: Math.ceil(item.file.size / PART_SIZE),
        file_size: item.file.size,
        status: 'Uploading',
      }

      this.waiting = true
      const ret = await this.$store.dispatch('generalUpload', payload)
      this.waiting = false

      if (ret.urls) {
        item.id = ret.file.id
        item.options.urls = ret.urls
        item.options.uploadParts = []

        item.callback = {
          onProgressUpdate: this.fileUploadUpdated,
          onSuccess: this.fileUploadSuccess,
          onFailed: this.fileUploadFailed,
          onCanceled: this.fileUploadCanceled,
        }
        UploadToS3(item)
      }
    },
    cancelFileUpload(item) {
      item.status.canceled = true
      for (var i = 0; i < item.uploadOptions.uploadXHR.length; ++i) {
        item.uploadOptions.uploadXHR[i].abort()
      }
      item.status.uploading = false
      this.fileUploadCanceled(item)
    },
    async fileUploadSuccess(file) {
      const payload = {
        id: file.id,
        status: 'Success',
        option: {
          upload_parts: file.options.uploadParts,
        },
      }
      await this.$store.dispatch('updateGenralUpload', payload)
      Vue.notify({
        group: 'messages',
        type: 'success',
        text: file.file.name + ' uploaded!',
      })
    },
    async fileUploadFailed(file) {
      await this.$store.dispatch('deleteGenrelUpload', file.id)
      Vue.notify({
        group: 'messages',
        type: 'error',
        text: file.file.name + ' upload failed!',
      })
    },
    async fileUploadCanceled(file) {
      await this.$store.dispatch('deleteGenrelUpload', file.id)
      Vue.notify({
        group: 'messages',
        type: 'warning',
        text: file.file.name + ' upload canceled!',
      })
    },
  },
  watch: {},
}
</script>

<style scoped>
</style>